@media (min-width: 1200px){
	.container-fluid{
		padding-left: 24px;
		padding-right: 24px;
	}
}
@media (min-width: 1200px) and (min-height: 720px){
	.login_flow_wrap{
		width: 980px;
	}
	.loginflow_right {
	    padding-left: 30px;
	}
	.loginflow_logo {
	    margin-bottom: 40px;
	}
	.frm_btn_full {
	    padding-top: 0;
	}
	.loginflow_right h1{
		font-size: 50px;
	}
	.loginflow_left_cap h4 {
	    font-size: 34px;
	    line-height: 40px;
	}
	.loginflow_left_cap p {
	    font-size: 16px;
	    line-height: 24px;
	}
	.loginflow_left_cap{
		padding: 40px 20px;
	}
}
@media (min-width: 1200px) and (min-height: 800px){
	.login_flow_wrap{
		width: 1110px;
	}
}
@media (min-width: 1280px) and (min-height: 900px){
	.login_flow_wrap{
		width: 1260px;
	}
}
@media (min-width: 1600px) and (min-height: 1000px){
	.login_flow_wrap{
		width: 1390px;
	}
}
@media (min-width: 1920px) and (min-height: 1024px){
	.login_flow_wrap{
		width: 1440px;
	}
	.loginflow_right h1 {
	    font-size: 68px;
	}
	.loginflow_logo {
	    margin-bottom: 70px;
	}
	.loginflow_right {
	    padding-left: 90px;
	}
	.frm_btn_full {
	    padding-top: 32px;
	}
	.loginflow_left_cap {
	    padding: 40px;
	}
	.loginflow_left_cap h4 {
	    font-size: 45px;
	    line-height: 50px;
	}
	.loginflow_left_cap p {
	    font-size: 20px;
	    line-height: 26px;
	}
}
@media (min-width: 1200px) and (max-height: 719px){
	.loginflow_right {
	    padding-left: 30px;
	}
	.loginflow_left_cap h4 {
	    font-size: 22px;
	    line-height: 28px;
	    margin-bottom: 10px;
	}
	.loginflow_left_cap p{
		font-size: 14px;
	}
	.loginflow_logo {
	    margin-bottom: 20px;
	}
	.loginflow_right h1 {
	    font-size: 34px;
	    margin-bottom: 30px;
	}
	.loginflow_left{
		border-radius: 15px;
	}
	.loginflow_left_cap {
	    padding: 20px;
	}
	.form_common .form_link{
		font-size: 14px;
	}
	.login_flow_wrap{
		width: 850px;
		padding: 10px;
	}
	.frm_btn_full {
	    padding-top: 0;
	}
	.form_common .form_group {
	    margin-bottom: 28px;
	}
	
}
@media (min-width: 992px){
	.sub_menu{
		display: block;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		transform: translate(0, 10px);
		transition: all .3s linear;
		margin-top: 10px;
	}
	.hdr_right li.has_child:hover .sub_menu{
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
		transform: translate(0, 0);
	}
	.sub_menu:after{
		content: "";
		position: absolute;
		left: 0;
		top: -10px;
		width: 100%;
		height: 10px;
		background: transparent;
		z-index: 2;
	}
}
@media (max-width: 1199px){
	.loginflow_right {
	    padding-left: 30px;
	}
	.loginflow_left_cap h4 {
	    font-size: 22px;
	    line-height: 28px;
	    margin-bottom: 10px;
	}
	.loginflow_left_cap p{
		font-size: 14px;
	}
	.loginflow_logo {
	    margin-bottom: 20px;
	}
	.loginflow_right h1 {
	    font-size: 34px;
	    margin-bottom: 30px;
	}
	.loginflow_left{
		border-radius: 15px;
	}
	.loginflow_left_cap {
	    padding: 20px;
	}
	.loginflow_logo, .loginflow_right h1{
		text-align: center;
	}
	.form_common .form_link{
		font-size: 14px;
	}
	.login_flow_wrap{
		width: 750px;
		padding: 10px;
	}
	.frm_btn_full {
	    padding-top: 0;
	}
}
@media (max-width: 991px){
	.header_sec .navbar-dark .navbar-toggler {
	    position: absolute;
	    right: 15px;
	    top: 23px;
	    padding: 0;
	    margin: 0;
	    border: 0;
	    outline: none;
	    box-shadow: none;
	}
	.hdr_right{
		margin-right: 45px;
	}
	.header_sec .navbar-collapse {
	    position: absolute;
	    left: 0;
	    right: 0;
	    top: 100%;
	    background: #212529;
	    padding: 10px 0;
	}
	.header_sec .navbar-dark .navbar-nav .nav-link{
		padding: 10px 15px;
		border-top: 1px solid #3a3b3d;
	}
	.nav_tabs_custom li{
		padding:0 7px;
	}
	.dataTables_filter .form-control{
		min-width: 140px;
	}
	.noti_item.d-flex{
		display: block !important;
		padding-right: 0;
	}
	.noti_date_main {
	    display: block;
	    padding: 5px 15px 0;
	}
	.noti_date_main span{
		display: inline-block;
		vertical-align: middle;
	}
	.noti_item > .noti_pera{
		padding-right: 0;
	}
	.view_shipment_main .nav-link{
		font-size: 16px;
		padding: 12px 0;
	}
	.view_shipment_main .nav-item {
	    padding: 0 10px;
	}
}
@media (max-width: 767px){
	body{
		font-size: 14px;
	}
	.loginflow_left{
		margin-top: 40px;
	}
	.loginflow_left_cap{
		text-align: center;
	}
	.loginflow_left{
		display: none;
	}
	.loginflow_right {
	    padding-left: 0px;
	}
	.loginflow_right{
		max-width: 400px;
		margin:0 auto;
	}
	div.dataTables_wrapper div.dataTables_paginate ul.pagination, 
	.dataTables_info ~ .dataTables_length, 
	div.dataTables_wrapper div.dataTables_info {
	    margin-top: 20px;
	    margin-bottom: 0;
	}
	div.dataTables_wrapper div.dataTables_paginate{
		margin: 20px 24px;
	}
	.datatable_cmn > thead th{
		vertical-align: middle;

	}
	.cont_hdr_item{
		margin-right: 5px;
	    padding: 6px 8px;
	}	
	.dataTables_filter .form-control {
	    font-size: 14px;
	    min-width: inherit;
	    width: 110px;
	}
	.body_admin{
		padding-bottom: 0px;
	}
	.footer_sec{
		position: inherit;
	}
	.table_normal_cmn tbody tr td,
	.table_normal_cmn thead tr th{
		font-size: 14px;
		padding: 8px;
	}
	.table_normal_cmn thead tr th:last-child, .table_normal_cmn tbody tr td:last-child,
	.table_normal_cmn thead tr th:first-child, .table_normal_cmn tbody tr td:first-child{
		padding: 8px;
	}
	
	.mobile_2row .nav_tabs_custom{
		border-bottom: 1px solid #ddd;
	}
	.mobile_2row .cont_hdr_right{
		padding: 15px 0;
		text-align: right;
	}
	.nav_tabs_custom li a{
		font-size: 16px;
	}
	.viewModal_fig {
		margin-bottom: 15px !important;
	}

}

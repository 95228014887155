body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input-error {
  color: red;
  text-align: right;
  display: block;
}
.input-error-left{
  text-align: left;
}
.form-control.error, .form-select.error, .form-control.error:focus, .form-select.error:focus{
  border: 1px solid red !important;
  color: red;
}
.form_common .btn_cmn:disabled {
  border: none;
  color: #fff;
}
.form-check-input:checked {
  background-color: #1b6875 !important;
  border-color: #1b6875 !important;
}
table.dataTable thead th:first-child,
table.dataTable thead th:last-child{
  border-radius: 8px;
}
/*.form-check-input{
  width: 1.4rem !important;
  height: 1.4rem !important;
  border: 1px solid #000 !important;
  margin: -2px 5px;
}*/

.rdt_TableCol, .rdt_TableCell, .rdt_Pagination{
  font-size: 16px !important;
}
.rdt_TableHeadRow{
  padding: 0.5rem 0 !important;
  background-color: rgb(255, 255, 255);
  border-bottom-width: 1px !important;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.2);
}
.ReactTable .rdt_TableHead{
  font-size: 16px !important;
  padding: 0 0 5px 0px !important;
  font-weight: 600;
}
.rdt_TableRow{
  border-bottom-width: 0px !important;
}
.ReactTable .rdt_TableCell{
  cursor: pointer;
  color: #525458;
  padding: .5rem .5rem;
}
.ReactTable .rdt_TableCol{
  padding: .5rem .5rem;
}
#dropdown-basic-menu{
    background: transparent;
    border: none;
    padding: 0;
}
#dropdown-basic-menu::after{
  display: none;
}

.modal_common.modal_medium .modal-dialog{
	max-width: 495px;
}
.basic-single .select__control {
  min-height: 48px;
  border-radius: 8px;
  border: 1.5px solid #E0E3E5;
  box-shadow: none;
}
.basic-single.error .select__control{
  border: 1.5px solid red;
}
.modal-change-password{
  z-index: 99999 !important;
}
.modal-change-password-backdrop{
  z-index: 9999 !important;
}
.vertical-center-custom{
  display: flex;
  align-items: center;
}
.vertical-center-custom .between-text{
  padding-top: 2px;
}
.custom-tags .badge{
  margin-right: 10px;
  background: transparent !important;
  border: 2px solid #c2c6cc;
  color: #000;
  padding: 4px 8px 6px 8px;
}
.custom-tags .btn-close{
  background-size: 10px 10px;
  padding-top:0px;
}
.header_sec .navbar.bg-dark {
  background: #032a31 !important;
 }
 .frm_btm_link > a .fa-arrow-left {
  margin-right: 8px;
  display: inline-block;
  vertical-align: middle;
  transition: all .3s linear;
  margin-top: -2px;
} 
.frm_btm_link > a:hover .fa-arrow-left {
  transform: translate(-5px, 0);
}
a:hover {
  color: #70ab37 !important;
}

.datatable-custom-design .dataTables_paginate .paginate_button {
  height: 32px !important;
  width: 32px !important;
  border-radius: 7px !important;
  background: transparent !important;
  border: 0 !important;
  padding: 5px !important;
  text-align: center !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #000 !important;
  margin: 0 2px !important;
}
.datatable-custom-design .dataTables_wrapper .dataTables_paginate .paginate_button.current,
.datatable-custom-design .dataTables_paginate .paginate_button.current, 
.datatable-custom-design .dataTables_paginate .paginate_button:hover, .datatable-custom-design .dataTables_paginate .paginate_button.current:hover{
background: #1B6875 !important;
color: #fff !important;
}
.datatable-custom-design1 .dataTables_paginate .paginate_button.previous,
.datatable-custom-design1 .dataTables_paginate .paginate_button.next{
background-image: url(/public/images/pagination_arrow.svg) !important;
background-color: transparent !important;
background-position: center !important;
background-repeat: no-repeat !important;
}
.datatable-custom-design .dataTables_paginate .paginate_button.previous,
.datatable-custom-design .dataTables_paginate .paginate_button.next{
height: 32px !important;
width: 32px !important;
}
.datatable-custom-design .dataTables_paginate, .datatable-custom-design div.dataTables_info {
  margin-top: 54px;
  margin-bottom: 24px;
}
.datatable-custom-design .dataTables_paginate .paginate_button.disabled{
opacity: 0.3 !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination, 
.dataTables_info ~ .dataTables_length, 
div.dataTables_wrapper div.dataTables_info {
  margin-top: 54px !important;
  margin-bottom: 24px !important;
}
.datatable-custom-design .dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 0;
}
.datatable-custom-design .datatable_cmn > thead th{
  border: 0 !important;
  color: #000;
  vertical-align: middle;
}
 .datatable-custom-design .datatable_cmn > tbody td {
  border: 0 !important;
  color: #525458;
  vertical-align: middle;
}
.datatable-custom-design.row-hover .datatable_cmn > tbody td {
  cursor: pointer;
}
.datatable-custom-design .dataTables_scrollHead {
  box-shadow: 0px 4px 8px 0px #0000000F;
  margin-bottom: 10px;
}
.datatable-custom-design .dataTables_info ~ .dataTables_length select {
  font-size: 16px !important;
  width: 60px;
  height: 29px;
  border: 1px solid #E0E3E5 !important;
  background-color: transparent !important;
  line-height: normal;
  margin-top: -3px;
}
.datatable-custom-design  div.dataTables_processing > div:last-child > div {
  background: #032a31 !important;
}

table.dataTable thead th {
  background: transparent !important;
  white-space: nowrap;
}

table.dataTable thead span.sort-icon {
  display: inline-block;
  padding-left: 10px;
  width: 16px;
  height: 16px;
  margin-left: 8px;
  margin-bottom: -3px;
}
.datatable-no-header thead{
display: none;
}

table.dataTable thead .sorting span { background: url('/public/images/sort_both.svg') no-repeat center right; }
table.dataTable thead .sorting_asc span { background: url('/public/images/sort_asc.svg') no-repeat center right; }
table.dataTable thead .sorting_desc span { background: url('/public/images/sort_desc.svg') no-repeat center right; }

table.dataTable thead .sorting_asc_disabled span { background: url('http://cdn.datatables.net/plug-ins/3cfcc339e89/integration/bootstrap/images/sort_asc_disabled.png') no-repeat center right; }
table.dataTable thead .sorting_desc_disabled span { background: url('http://cdn.datatables.net/plug-ins/3cfcc339e89/integration/bootstrap/images/sort_desc_disabled.png') no-repeat center right; }

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  content: '' !important;
}
.sorting_disabled{
  pointer-events: none;
}
.view_shipment_main{
  border-radius: 8px;
}
.custom-btn-padding {
  margin: 0;
  padding: 7px 12px !important;
}
.dropdonw-selected-tags .custom-tag input{
  display: none;
}
.dropdonw-selected-tags .custom-tag{
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-block;
}
.dropdonw-selected-tags .custom-tag div{
  display: inline-block;
}
.single-custom-dropdown-multiple {
  background-color: transparent !important;
  color: #000 !important;
  text-align: left !important;
  min-height: 48px;
  border: 1.5px solid #E0E3E5 !important;
}
.single-custom-dropdown-multiple:hover {
  background-color: transparent;
}
.single-custom-dropdown {
  background-color: transparent !important;
  color: #000 !important;
  text-align: left !important;
}
.single-custom-dropdown:hover {
  background-color: transparent;
}
.react-select-container{
  border: none;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px;
    border: 1.5px solid #E0E3E5;
    border-radius: 8px;
    margin-top: 10px;
}
span.select-checkbox-label{
  margin-left: 5px;
}
.react-select-container [aria-selected="true"], .react-select-container [aria-selected="false"] {
  background: none;
  color: #000;
}
.react-select-container-absolute{
  position: absolute !important;
  background-color: #FFF;
  width: 90%;
  max-width: 425px;
  z-index: 99;
}
.react-select-container-absolute-col-6{
  max-width: 425px !important;
  
}
.custom-calender .react-datepicker__view-calendar-icon input {
  padding: 6px 25px 5px 10px;
}
.custom-calender .react-datepicker__input-container .react-datepicker__calendar-icon{
  right: 0;
  width: 20px;
  height: 20px;
  padding: 0.8rem !important;
}
.custom-calender .react-datepicker-wrapper{
  width: 100%;
}
button.btn.btn_cmn.custom-btn-padding img {
  margin-top: -3px;
}
.dataTable .thumbnail-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.dataTable .thumbnail-item {
  flex: 0 0 calc(25% - 10px); /* Adjust width as needed */
  max-width: calc(25% - 10px); /* Adjust max-width as needed */
}
.input_file_custom_media {
  position: relative;
}
.input_file_custom_media input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}
.input_file_custom_media .inputtype_file_btn {
  border: 1.5px solid #E0E3E5;
  min-height: 214px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  color: #6F7277;
}
.input_file_custom_media.has-files .inputtype_file_btn{
  min-height: 145px;
}
.preview_image {
  border: 1.5px solid #E0E3E5;
  display: flex;
  align-items: center;
  padding: 8px;
  margin-top: 8px;
  border-radius: 8px;
}
.preview_thumb {
  width: 50px;
  margin-right: 12px;
}
.preview_text p {
  margin: 0;
  font-size: 16px;
  color: #525458;
  line-height: 20px;
}
.preview_close {
  margin-left: auto;
  height: 24px;
  width: 24px;
  text-align: center;
}
.input_file_custom_media .inputtype_file_btn img {
  margin-bottom: 15px;
}
.react-select-container [aria-selected=false], .react-select-container [aria-selected=true] {
  background: none !important;
}
.view_shipment_main .nav-link.active.median-nav-link:after {
  opacity: 0;
}
@media only screen and (min-width : 992px) {
  .Toastify__toast-container{
    width:auto !important;
    min-width: 320px;
  }
}
@media (min-width: 1200px) and (min-height: 800px){
  .main_vert_center .login_flow_wrap {
      width: 1200px;
  }
}
@media (max-width: 1200px){
  .dataTable .thumbnail-item {
    flex: 0 0 calc(33% - 10px); /* Adjust width as needed */
    max-width: calc(33% - 10px); /* Adjust max-width as needed */
  }
}
@media (max-width: 960px){
  .dataTable .thumbnail-item {
    flex: 0 0 calc(50% - 10px); /* Adjust width as needed */
    max-width: calc(50% - 10px); /* Adjust max-width as needed */
  }
}
@media (max-width: 767px){
  .dataTable .thumbnail-item {
    flex: 0 0 calc(100% - 10px); /* Adjust width as needed */
    max-width: calc(100% - 10px); /* Adjust max-width as needed */
  }
}

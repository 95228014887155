@font-face {
    font-family: 'calibri';
    src: url('../fonts/CalibriRegular.eot');
    src: url('../fonts/CalibriRegular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/CalibriRegular.woff') format('woff'),
         url('../fonts/CalibriRegular.ttf') format('truetype'),
         url('../fonts/CalibriRegular.svg#Sri-TSCRegular') format('svg');
    font-weight: 400;
    font-style: normal;
   }
@font-face {
    font-family: 'calibri';
    src: url('../fonts/calibri_bold.woff2') format('woff2'),
         url('../fonts/calibri_bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
a{
	text-decoration: none;
	color: #1b6875;
}
a:hover{
	color: #70ab37;
}
body{
	font-family: 'calibri';
	font-size: 16px;
}
img{
	max-width: 100%;
	height: auto;
}
h1,h2,h3,h4{
	font-weight: 700;
}
/********* css for login flow start**********/
.main_vert_center{
	min-height: 100vh;
	align-items: center;
	display: flex;
}
.login_flow_wrap{
	width: 1140px;
	max-width: 100%;
	margin: 0 auto;
	padding: 24px;
}
.loginflow_left{
	border-radius: 48px;
	overflow: hidden;
	position: relative;
}
.loginflow_left figure{
	margin: 0;
}
.loginflow_left figure img{
	max-width: 100%;
}
.loginflow_left_cap{
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	padding: 40px;
	color: #fff;
}
.loginflow_left_cap h4{
	font-size: 45px;
	line-height: 50px;
	letter-spacing: -1px;
	margin-bottom: 16px;
}
.loginflow_left_cap p{
	font-size: 20px;
	line-height: 26px;
}
.form_common .form_group{
	margin-bottom: 34px;
	position: relative;
}
.loginflow_right{
	padding-left: 90px;
}
.loginflow_logo{
	margin-bottom: 70px;
}
.loginflow_right h1{
	font-size: 68px;
	letter-spacing: -1.5px;
	margin-bottom: 40px;
}
.form_common .form_group .form-label {
    position: absolute;
    left: 15px;
    top: 12px;
    z-index: 9;
    font-size: 16px;
    color: #6F7277;
    margin: 0;
    pointer-events: none;
    transition: all .2s linear;
}
.form_common .form_group.focus_label .form-label {
    top: -26px;
    left: 0;
}
.req_star{
	color: #E53935;
}
.form_common .form-control{
	height: 48px;
	border-radius: 8px;
	border: 1.5px solid #E0E3E5;
	box-shadow: none;
}
.form_common .form-check{
	font-size: 18px;
	color: #6F7277;
}
.form_common .form_link{
	font-size: 18px;
	color: #000;
}
.form_common .form_link:hover{
	color: #1b6875;
}
.btn_cmn{
	display: inline-block;
	font-size: 16px;
	font-weight: 700;
	color: #fff;
	background: #1B6875;
	border-radius: 8px;
	padding: 11px 15px;
}
.btn_cmn:hover{
	background: #70ab37;
	color: #fff;	
}
.frm_btn_full .btn_cmn{
	width: 100%;
}
.check_custom{
	position: relative;
	padding-left: 28px;
}
.check_squire {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 18px;
    width: 18px;
    border-radius: 4px;
    background: transparent;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 3px;
    border: 1px solid #1B6875;
}
.check_custom input[type="checkbox"]:checked ~ .check_squire{
	background: #1B6875;
}
.check_custom input[type="checkbox"]{
	display: none;
}
.pass_vis_icon {
    position: absolute;
    top: 12px;
    right: 15px;
    color: #909090;
    cursor: pointer;
    height: 24px;
    width: 24px;
    line-height: normal;
}
.iconeyeslash, .pass_vis_icon.on .iconeye {
    display: none;
}
.pass_vis_icon.on .iconeyeslash {
    display: block;
}
.frm_btn_full{
	padding-top: 32px;
}
.frm_btm_link{
	padding-top: 17px;
}
.frm_btm_link > a {
    font-size: 16px;
    font-weight: 700;
}
.frm_btm_link > a img{
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
    transition: all .3s linear;
}
.frm_btm_link > a:hover img{
	transform: translate(-5px, 0);
}
.form_common .btn_cmn:disabled{
	background: rgba(0,0,0,0.2);
}
/********* css for login flow end**********/


/** header css start **/
.header_sec {
    position: relative;
    z-index: 99;
}
.header_sec .navbar.bg-dark{
	background: #16525C;
	padding-top: 16px;
	padding-bottom: 16px;
}
.header_sec .navbar-dark .navbar-brand{
	padding: 0;
	margin-right: 64px;
}
.header_sec .navbar-dark .navbar-nav .nav-link{
	color: #6C969A;
	font-size: 18px;
	line-height: normal;
	padding: 0 16px;
}
.header_sec .navbar-dark .navbar-nav .nav-link.active{
	font-weight: 700;
	color: #fff;
}
.hdr_right{
	padding: 0;
	margin: 0;
}
.hdr_right > li{
	margin-left: 16px;
}
.hdr_right > li a{
	position: relative;
}
.noti_badge{
	position: absolute;
	right: 0;
	top: 3px;
	height: 10px;
	width: 10px;
	background: #E53935;
	border: 2px solid #032A31;
	border-radius: 50%;
}
.bg_light{
	background:#E6F1F3;
}
.content_hdr_sec{
	background: #FFFFFF;
	box-shadow: 0px 4px 8px 0px #0000000F;
	padding: 16px 0;
	margin-bottom: 24px;
}
.page_head{
	font-size: 18px;
	margin-bottom: 0;
}
.cont_hdr_item {
    border: 1px solid #E0E3E5;
    height: 36px;
    padding: 6px 12px;
    border-radius: 8px;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    line-height: normal;
}
.cont_hdr_item:last-child{
	margin-right: 0;
}

.body_admin{
	padding-bottom: 50px;

	min-height: 100vh;
	position: relative;
}
.footer_sec {
    position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 12px 0;
	z-index: -1;
}
.hdr_right li{
	position: relative;
}
.sub_menu {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 0;
    top: 100%;
    background: #fff;
    min-width: 188px;
    border-radius: 8px;
    box-shadow: 0px 8px 16px 0px #00000014;
    padding: 8px 0;
    display: none;
}
.sub_menu li a{
	padding: 8px 12px;
	display: block;
	font-size: 18px;
	color: #000;
}
.sub_menu li a img{
	margin-right: 8px;
}

/** header css end **/

/**all contact page css start**/
.dataTables_filter{
	font-size: 0;
	display: inline-block;
	vertical-align: middle;
}
.dataTables_filter .form-control {
    font-size: 16px;
    color: #000;
    padding: 0;
    line-height: normal;
    border: 0;
    min-height: inherit;
    margin-left: 8px;
    box-shadow: none;
    min-width: 200px;
}.dataTables_filter .form-control::-webkit-input-placeholder{color: #000;}
.dataTables_filter .form-control:-moz-placeholder{color:#000;}
.dataTables_filter .form-control::-moz-placeholder{color:#000;}
.dataTables_filter .form-control:-ms-input-placeholder{ color:#000;}
.dataTables_length{
	display: none;
}
.dataTables_info ~ .dataTables_length, 
div.dataTables_wrapper div.dataTables_info{
	display: inline-block;
	vertical-align: middle;
	padding: 0;
	margin: 0;
}

.dataTables_info ~ .dataTables_length{
	font-size: 0;
	margin-left: 20px !important;
}
.dataTables_info ~ .dataTables_length select{
	font-size: 16px;
	width: 60px;
	height: 29px;
	border: 1px solid #E0E3E5;
	background-color: transparent;
	line-height: normal;
}
.data_table_main .data_table_wrap{
	background: #fff;
	border-radius: 8px;
}
table.dataTable thead th:first-child,
table.dataTable tbody td:first-child{
	padding-left: 24px;
}
table.dataTable thead th:last-child,
table.dataTable tbody td:last-child{
	padding-right: 24px;
}
div.dataTables_wrapper div.dataTables_info{
	padding-left: 24px;
}
.datatable_cmn > thead{
	box-shadow: 0px 4px 8px 0px #0000000F;
	border: 0;
}
.datatable_cmn > thead th,
.datatable_cmn > tbody td{
	border: 0;
	color: #525458;
	vertical-align: middle;
}
.datatable_cmn > thead th{
	color: #000;
}
table.dataTable.table-striped>tbody>tr:nth-of-type(2n+1)>*{
	box-shadow:none;
}
.dataTables_paginate .page-link {
    height: 32px;
    width: 32px;
    border-radius: 7px;
    background: transparent;
    border: 0;
    padding: 5px;
    text-align: center;
    display: block;
    font-size: 14px;
    line-height: 22px;
    color: #000;
    margin: 0 2px;
}
.dataTables_paginate .page-item.active .page-link, 
.dataTables_paginate .page-link:hover{
	background: #1B6875;
	color: #fff;
}
.dataTables_paginate .page-item.previous,
.dataTables_paginate .page-item.next{
	background-image: url(/public/images/pagination_arrow.svg);
	background-color: transparent;
	background-position: center;
	background-repeat: no-repeat;
}
.dataTables_paginate .page-item.previous a,
.dataTables_paginate .page-item.next a{
	opacity: 0;
	height: 32px;
	width: 32px;
}
.dataTables_paginate .page-item.previous{
	transform: rotate(-180deg);
}
.dataTables_paginate .page-item.disabled{
	opacity: 0.1;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination, 
.dataTables_info ~ .dataTables_length, 
div.dataTables_wrapper div.dataTables_info {
    margin-top: 54px;
    margin-bottom: 24px;
}
div.dataTables_wrapper div.dataTables_paginate{
	margin-right: 24px;
}
table.dataTable thead>tr>th.sorting:before,
table.dataTable thead>tr>th.sorting:after{
	font-size: 0.70em;
}
.modal_common .modal-content{
	border: 0;
	border-radius: 8px;
}
.modal_common .modal-header{
	box-shadow: 0px 4px 8px 0px #0000000F;
	border: 0;
	padding: 15px 24px;
}
.modal_common .modal-header .modal-title{
	font-size: 18px;
	font-weight: 700;
	line-height: 26px;
}
.modal_common .modal-header .btn {
    font-size: 16px;
    font-weight: 400;
    color: #1B6875;
    padding: 0;
    display: inline-flex;
    align-items: center;
}
.modal_common .modal-header .btn img{
	margin-right: 8px;
}
.modal_common .modal-footer{
	box-shadow: 0px -4px 8px 0px #0000000F;
	padding: 15px 24px;
	border: 0;
}
.modal_common .modal-footer .btn {
    margin: 0;
    padding: 7px 12px;
}
.modal_common .modal-body{
	padding: 24px;
}
.modal_common .modal-dialog{
	max-width: 920px;
}
.form_normal label.form-label {
    color: #6F7277;
    margin-bottom: 3px;
}
.form_normal .form-control,
.form_normal .form-select{
	height: 48px;
	border-radius: 8px;
	border: 1.5px solid #E0E3E5;
	box-shadow: none;
}
.form_normal .form-control[readonly] {
    background-color: transparent;
    color: #6F7277;
}
.table_normal_cmn thead tr th{
	color: #000000;
	font-size: 16px;
	font-weight: 700;
	padding: 15px;
}
.table_normal_cmn tbody tr td{
	font-size: 18px;
	line-height: 23.4px;
	color: #525458;
	padding: 15px;
}
.table_normal_cmn thead tr th:first-child,
.table_normal_cmn tbody tr td:first-child{
	padding-left: 24px;
}
.table_normal_cmn thead tr th:last-child,
.table_normal_cmn tbody tr td:last-child{
	padding-right: 24px;
}
.table_normal_cmn .table-light{
	background: #F0F1F2;
}
.table_normal_cmn {
    border-radius: 8px;
    overflow: hidden;
    margin-top: 8px;
}
.table_normal_cmn .table-light {
    --bs-table-bg: #F0F1F2;
}
.modal_common .modal-footer .btn{
	margin-left: 8px;
}
.mdl_btn_cancel{
	background: transparent;
	border: 1px solid #E0E3E5;
	color: #000;
}
.mdl_btn_cancel:hover,
.mdl_btn_close:hover{
	background: #E53935;
	color: #fff;
	border-color: #E53935;
}


/**all contact page css end**/

/*** shipment page start ***/
.nav_tabs_custom{
	list-style: none;
	padding: 0;
	margin: 0;
}
.nav_tabs_custom li{
	padding: 0 16px;
	display: inline-block;
	vertical-align: bottom;
}
.nav_tabs_custom li a{
	padding: 18px 0px;
	font-size: 18px;
	font-weight: 400;
	border: 0;
	background: transparent;
	margin: 0;
	color: #6F7277;
	line-height: 32px;
	position: relative;
	display: block;
}
span.tab_badge {
    height: 25px;
    min-width: 25px;
    display: inline-block;
    background: #F0F1F2;
    border-radius: 50%;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    margin-left: 4px;
    color: #000;
    font-weight: 400;
    outline: none;
}
.nav_tabs_custom li.active a{
	font-weight: 700;
	color: #000;
/*	font-size: 20px;*/
}
.nav_tabs_custom li a:after{
	content: "";
	background: #1B6875;
	height: 2px;
	width: 100%;
	position: absolute;	
	left: 0;
	bottom: 0;
	opacity: 0;
}
.nav_tabs_custom li.active a:after{
	opacity: 1;
}
.modal_common .modal-dialog.modal_medium{
	max-width: 495px;
}
.modal_common .modal-header .btn_clear{
	color: #000;
}
.pop_filter_tab .tab-pane{
	min-height: 260px;
	padding: 16px 24px;
}
.pop_filter_tab .tab-content{
	width: 100%;
	border-left: 1px solid #E0E3E5; 
}
.pop_filter_tab .nav-pills{
	margin-right: 16px;
	padding: 16px 0;
}
.pop_filter_tab .nav-pills .nav-link {
    margin-bottom: 8px;
    background: transparent;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    border-radius: 4px;
    padding: 4px 8px;
    color: #6F7277;
    min-width: 85px;
}
.pop_filter_tab .nav-pills .nav-link.active{
	background: rgba(27, 104, 117, 0.12);
	font-weight: 700;
	color: #000;
}
.t_border_btn{
	color: #525458;
	border: 1px solid #D1D4D8;
	border-radius: 4px;
}
.t_border_btn {
    color: #525458;
    border: 1px solid #D1D4D8;
    border-radius: 4px;
    padding: 3px 5px;
    display: inline-block;
    white-space: nowrap;
    line-height: 1;
    
}
.font_small{
	font-size: 14px;
}
.table_v_scroll .table-responsive{
	max-height: 350px;
}
body .mCSB_scrollTools .mCSB_draggerContainer{
	width: 2px;
	background: rgba(0,0,0,0.15);
}
body .mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail{
	background: transparent;
}
.back_link{
	margin-right: 8px;
}
.view_shipment_main{
	background: #fff;
}
.view_shipment_main .nav-item{
	padding: 0 16px;
}
.view_shipment_main .nav-link{
	padding: 18px 0px;
	font-size: 18px;
	font-weight: 400;
	border: 0;
	background: transparent;
	margin: 0;
	color: #6F7277;
	line-height: 32px;
	position: relative;
	display: block;
}

.view_shipment_main .nav-link.active{
	font-weight: 700;
	color: #000;
/*	font-size: 20px;*/
}
.view_shipment_main .nav-link:after{
	content: "";
	background: #1B6875;
	height: 2px;
	width: 100%;
	position: absolute;	
	left: 0;
	bottom: 0;
	opacity: 0;
}
.view_shipment_main .nav-link.active:after{
	opacity: 1;
}
.view_shipment_main .nav-tabs{
	padding: 0 24px;
	box-shadow: 0px 4px 8px 0px #0000000F;
	border: 0;
}
.view_ship_cont {
    padding: 24px;
    min-height: calc(100vh - 280px);
}
.view_ship_ftr {
    box-shadow: 0px -4px 8px 0px #0000000F;
    padding: 15px 24px;
    border: 0;
}
.view_ship_ftr .btn {
    margin: 0 0 0 8px;
    padding: 7px 12px;
}
.selected_tag_list > span{
	color: #525458;
    border: 1px solid #D1D4D8;
    border-radius: 4px;
    padding: 3px 5px;
    display: inline-block;
    white-space: nowrap;
    line-height: 1;
    font-size: 12px;
    margin-top: 10px;
}
.form_normal textarea.form-control{
	height: 81px;
}
.input_file_custom{
	position: relative;
	display: inline-block;
}
.input_file_custom input[type="file"]{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	z-index: 2;
}
.inputtype_file_btn {
    font-weight: 700;
    color: #1B6875;
    border: 1px solid #1B6875;
    border-radius: 8px;
    padding: 3px 10px;
}
.inputtype_file_btn img{
	margin-right: 8px;
}
.form_normal .input-group .form-control{
	border-right: 0;
}
.form_normal .input-group .input-group-text{
	background: transparent;
}
.btn_border_cmn{
	font-size: 16px;
	font-weight: 700;
	color: #1B6875;
	border: 1.5px solid #1B6875;
	border-radius: 8px;
}
.btn_border_cmn img{
	margin-right: 8px;
}
.btn_border_cmn:hover {
    color: #1B6875;
}
.noti_history_page{
	height: 100%;
	border-left: 1.5px solid #E0E3E5;
}
.noti_header{
	padding: 13px 24px;
	font-size: 18px;
	font-weight: 700;
	border-bottom: 1.5px solid #E0E3E5;
}
.noti_item{
	padding:16px 24px;
}
.noti_date_main > span{
	display: block;
	font-size: 12px;
	width: 70px;
}
.noti_item > .noti_pera{
	width: 100%;
	margin-bottom: 0;
	padding-right: 40px;
	position: relative;
	padding-left: 15px;
}
.noti_pera:before{
	content: "";
	position: absolute;
	left: 0;
	top: 10px;
	height: 4px;
	width: 4px;
	border-radius: 50%;
	background: #000;
}
#sendNotifiModal .modal-dialog{
	max-width: 310px;
	margin: 0 auto;
}
#sendNotifiModal .modal-content{
	border-radius: 16px;
	border: 0;
}
.send_noti_popup figure{
	margin-bottom: 10px;
}
.send_noti_popup p{
	font-size: 16px;	
}
.color_red{
	color: #E53935;
}
/*** shipment page end ***/

/**** media page start ****/
.media_wrap{
	background: #fff;
	border-radius: 8px;
	padding: 24px;
}
.media_date_by ul{
	padding: 0;
	list-style: none;
	margin: 0;
}
.media_date_by ul li{
	display: inline-block;
	vertical-align: middle;
	line-height: 1;
	color: #6F7277;
}
.media_date_by{
	position: relative;
	padding-right: 70px;
	margin: 10px 0 8px;
}
.media_veiw_del{
	position: absolute;
	right: 0;
	top: 0;
}
.media_veiw_del li:last-child{
	margin-left: 4px;
}
.media_veiw_del li a{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 26px;
	width: 26px;
	background: #F0F1F2;
	border-radius: 50%;
}
.date_by_list li:first-child{
	padding-right: 8px;
	border-right: 1px solid #6F7277;
	margin-right: 4px;
}
.media_title {
    line-height: 20px;
}
.media_fig{
	border-radius: 8px;
	overflow: hidden;
	backface-visibility: hidden;
	position: relative;
	padding-top: 60%;
}
.media_fig img{
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	object-fit: cover;
	object-position: center;
}
.play_med {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.3);
    cursor: pointer;
}
.play_med img{
	width: 48px;
	height: 48px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
#viewMedia .modal-dialog {
    max-width: 732px;
    color: #6F7277;
}
.viewModal_fig{
	border-radius: 8px;
	overflow: hidden;
}
#viewMedia .form-control{
	color: #6F7277;
}
#deleteModal .modal-dialog{
	max-width: 310px;
}
#deleteModal .modal-body {
    padding: 24px 16px;
}
#deleteModal p {
    color: #6F7277;
    line-height: 20px;
    margin-bottom: 24px;
}
.del_modal_ftrbtn .btn{
	width: 135px;
}
.alert_right{
	position: absolute;
	right: 65px;
	top: 49px;
	padding:10px 12px;
	border-radius: 5px;
	box-shadow: 0px 4px 8px 0px #0000000F;
	font-size: 20px;
	z-index: 999;
	display: none;
}
.alert_right.alert_succ{
	background: #15D36E;
	color: #fff;
}
.alert_succ > img{
	margin-right: 8px;
}
#video{
	width: 100%;
	height: 400px;
	margin-bottom: 0;
}

/**** media page end ****/

/** radio custom start**/
.radio_custom {
    position: relative;
    font-weight: 600;
    color: #6F7277;
    font-size: 12px;
    padding-left: 26px;
    min-height: inherit;
}
span.radio_cir {
    display: block;
    height: 18px;
    width: 18px;
    border: 1px solid #6F7277;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
}
.radio_custom input[type="radio"]{
	display: none;
}
.radio_custom input[type="radio"]:checked ~ span{
	border: 5px solid #1b6875;
}
/** radio custom end**/